import { Modal } from 'antd';

import { IModalVideoProps } from './IModalVideoProps';

import './ModalVideo.scss';

const ModalVideo = (props: IModalVideoProps) => (
	<Modal
		className='video-modal'
        width={640}
        open={props.modalVideoVisible}
        onOk={() => props.setModalVideoVisible(false)}
        onCancel={() => props.setModalVideoVisible(false)}
        footer={[null,null]}
		destroyOnClose
	>
		<iframe
			title='video'
			style={{marginTop:30}} 
			src={props.url} 
			width="99%" 
			height="361" 
			allow="autoplay; fullscreen">
		</iframe>
	</Modal>
);

export default ModalVideo;
