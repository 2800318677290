import { AxiosWebAPI } from './api';
import { NotificationsModel } from '../Modelos/notifications.model';

const NotificationsAPI = {
    GetInvestorPortalNotifications(UserId:number):Promise<NotificationsModel> {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`/Notifications/GetInvestorPortalNotifications?UserId=${UserId}`)
                .then((data) => { 
                    if (data) {
                        return resolve(data.data);
                    }
                })
        });
    },
    DontShowAnyMore(NotificationId:number) {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`/Notifications/DontShowAnyMore?NotificationId=${NotificationId}`)
                .then((data) => { 
                    if (data) {
                        return resolve(data.data);
                    }
                })
        });
    }
}

export default NotificationsAPI;