import { Tree, Row, Col, Spin, Button } from 'antd';
import Search from 'antd/lib/input/Search';

import { IInvestorsTableProps } from './IInvestorsTableProps';

import styles from './InvestorsTable.module.scss';
import './TablaInversores.css';

const InvestorsTable = ({
	loading,
	translations,
	filterGrid,
	setVisible,
	onExpand,
	expandedKeys,
	autoExpandParent,
	onCheck,
	checkedKeys,
	onSelect,
	selectedKeys,
	dataSourceTree,
	totalGrid
}: IInvestorsTableProps) => (
	<div className={`investors-table ${styles.investorsTable}`}>
		{loading ?
			<div className='contenedorSpiner'>
				<Spin /> <br>
				</br>
				<h2>{translations.cargando}</h2>
				</div>
			:
			<>
				<Row className="investors-table-header" justify="space-between" align="middle">
					<Col xs={0} sm={0} md={20} lg={20} xl={20}>
						<Row align="middle">
							<Col>
								<div className="title-tab" style={{ marginRight: 10 }}>
									{translations.buscar}
								</div>
							</Col>
							<Col xs={0} sm={0} md={8} lg={8} xl={8}>
								<Search
									placeholder={translations.busquedaHolder}
									onSearch={filterGrid}
									style={{ width: '100%' }}
								/>
							</Col>
						</Row>
					</Col>
					<Col>
						<Button type='primary' className="btn" style={{backgroundColor:'#001435'}} onClick={()=> setVisible(true)}>{translations.descargarExcel}</Button>
					</Col>
				</Row>
				<Tree
					checkable
					onExpand={onExpand}
					expandedKeys={expandedKeys}
					defaultExpandAll
					autoExpandParent={autoExpandParent}
					onCheck={onCheck}
					checkedKeys={checkedKeys}
					onSelect={onSelect}
					selectedKeys={selectedKeys}
					treeData={dataSourceTree}
				/>
				<Row className="fila-encabezado-table" style={{ marginTop: 0 }}>
					<Col xs={0} sm={0} md={8} lg={8} xl={8} style={{ textAlign: 'center' }}>
						TOTAL
					</Col>
					<Col xs={0} sm={0} md={4} lg={4} xl={4}>
						{totalGrid.length > 0 && `€ ${totalGrid[0].commitment.toFixed(2)}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
					</Col>
					<Col xs={0} sm={0} md={4} lg={4} xl={4}>
						{totalGrid.length > 0 && `€ ${totalGrid[0].amount.toFixed(2)}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
					</Col>
					<Col xs={0} sm={0} md={4} lg={4} xl={4}>
						{totalGrid.length > 0 && `€ ${totalGrid[0].capital_Gain.toFixed(2)}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
					</Col>
					<Col xs={0} sm={0} md={4} lg={4} xl={4} style={{ paddingRight: '30px' }}>
						{totalGrid.length > 0 && `${totalGrid[0].shares.toFixed(2)}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
					</Col>
				</Row>
			</>
		}
	</div>
);

export default InvestorsTable;
