import { AxiosWebAPI } from "./api";

const PDFAPI = {
    GenerateCapitalAccount(data:any):Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.post(`/PDF/GenerateCapitalAccount`,data, {
                responseType: 'arraybuffer',
                headers: {
                  'Accept': 'application/pdf'
                }
            })
            .then((data) => { 
                if (data !== null) {
                    return resolve(data.data);
                } else {
                    return reject(null);
                }
            });
        });
    },
    GenerateCapitalCall(data:any):Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.post(`/PDF/GenerateCapitalCall`,data, {
                responseType: 'arraybuffer',
                headers: {
                  'Accept': 'application/pdf'
                }
            })
            .then((data) => { 
                if (data !== null) {
                    return resolve(data.data);
                } else {
                    return reject(null);
                }
            });
        });
    },
    GenerateDistributionNotice(data:any):Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.post(`/PDF/GenerateDistributionNotice`,data, {
                responseType: 'arraybuffer',
                headers: {
                  'Accept': 'application/pdf'
                }
            })
            .then((data) => { 
                if (data !== null) {
                    return resolve(data.data);
                } else {
                    return reject(null);
                }
            });
        });
    }
}

export default PDFAPI;