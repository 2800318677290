import { AxiosWebAPI } from './api';
import { Vehiculo } from '../Modelos/Vehicle.model';

const VehicleAPI = {
    get(): Promise<Array<Vehiculo>> {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`/Vehicle`)
                .then(json => resolve(json.data))
                .catch((error) => {
                    reject(error)
                });
        });
    },
    getVehiclePeip(id: number) {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`/Vehicle/${id}`)
                .then(json => resolve(json.data))
                .catch((error) => {
                    reject(error)
                });
        });
    },
    postVehicle(data: Vehiculo) {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.post(`/Vehicle`, data)
                .then(json => resolve(json))
                .catch((error) => {
                    reject(error)
                });
        })
    }, 
}

export default VehicleAPI;