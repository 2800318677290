import axios from 'axios';

import { AxiosServiceAPI } from '../Api/api';
import {Utils} from '../utils/Utils';

const url_inversores = process.env.REACT_APP_ServiceUrl;
export class reportesService {
    public obtenerReportes(name: string, url: string, parameters: string, year: string, month: string, day: string): Promise<any[]>{
        return new Promise((resolve,reject)=>{
            try {
                AxiosServiceAPI.get(`${url_inversores}/reports/getreport?name=${name}&url=${url}&parameters=${parameters}&year=${year}&month=${month}&day=${day}`)
                    .then((data) => { 
                        if (data.data.code === 200) {
                            return resolve(data.data.result);
                        }
                        else {
                            return reject(null)
                        }
                    });
            } catch (error) {
                return reject(null);
            }
        });
    }

    public descargarExcel(name:string,url:string, Investor:string):Promise<any[]>{
        return new Promise((resolve,reject)=>{
            try {
                window.location.href=`${process.env.REACT_APP_ServiceUrl}/reports/getexcelpivot?name=${name}&url=${url}&IdContact=${Investor}`;; 
            } catch (error) {
                return reject(null);
            }
        });
    }

    public tiposReporte():Promise<any[]>{
        return new Promise((resolve,reject)=>{
            try {
                AxiosServiceAPI.get(`/reports/getlistreport`)
                    .then((data) => { 
                        if (data !== null) {
                            return resolve(data.data.result);
                        }
                        else {
                            return reject(null);
                        }
                    });
            } catch (error) {
                return reject(null);
            }
        });
    }

    public listarparametros(idReport:number):Promise<any[]>{
        return new Promise((resolve,reject)=>{
            try {
                Utils.getConfig().then((config: any) => {
                    axios.get(`${config.data[0].url_reports}/reports/getdetailtreport?idReport=${idReport}`)
                        .then((data) => { 
                            if (data !== null) {
                                return resolve(data.data.result);
                            } else {
                                return reject(null);
                            }
                        })
                });
            } catch (error) {
                return reject(null);
            }
        });
    }

}