import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';

import './Grafico.css';

const InformacionInteres = (props: any) => {
	const [compromiso, setCommitment] = useState<number>();
	const [contribuciones, setContributions] = useState<number>();
	const [distribuciones, setDistributions] = useState<number>();

	useEffect(() => {
		configurarDataGrafico();
  	}, [props.compromiso, props.contribuciones, props.distribuciones]);

	const configurarDataGrafico = () => {
		let altoDiv = 130;
		let mayorVal = 1;
		if (props.compromiso > props.contribuciones) {
			if (props.compromiso > props.distribuciones) {
				mayorVal = props.compromiso;
			} else {
				mayorVal = props.distribuciones;
			}
		} else {
			mayorVal = props.contribuciones;
		}

		if (mayorVal === 0) {
			mayorVal = 1;
		}
		let comp = (props.compromiso * 100) / mayorVal;
		let cont = (props.contribuciones * 100) / mayorVal;
		let dis = (props.distribuciones * 100) / mayorVal;

		comp = (altoDiv * comp) / 100;
		cont = (altoDiv * cont) / 100;
		dis = (altoDiv * dis) / 100;

		setCommitment(comp);
		setContributions(cont);
		setDistributions(dis);
	};

	const nFormatter = (num: any) => {
		if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + ' g';
		}
		if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace(/\.0$/, '') + ' m';
		}
		if (num >= 1000) {
			return (num / 1000).toFixed(1).replace(/\.0$/, '') + ' k';
		}
		return num;
	};


	return (
		<Row className="contenedor-barras" justify="space-between" align="bottom">
			<Col span="8">
				<div className="label-Total">€ {props.compromiso > 0 ? nFormatter(props.compromiso) : 0} </div>
				<div className={`barra-grafico`} style={{ height: compromiso,backgroundColor:'#001435'}}></div>
				<div className="label-Total">{props.translations.compromisoGrafico}</div>
			</Col>
			<Col span="8">
				<div className="label-Total">€ {props.contribuciones > 0 ? nFormatter(props.contribuciones) : 0} </div>
				<div className="barra-grafico Contribuciones" style={{ height: contribuciones }}></div>
				<div className="label-Total">{props.translations.contribuciones}</div>
			</Col>
			<Col span="8">
				<div className="label-Total">€ {props.distribuciones > 0 ? nFormatter(props.distribuciones) : 0} </div>
				<div className="barra-grafico Distribuciones"></div>
				<div className="label-Total">Distrib.</div>
			</Col>
		</Row>
	);
};

export default InformacionInteres;
