import { Col, Row } from 'antd';

import styles from './UnderMaintenance.module.scss';
import { useParams } from 'react-router-dom';

const UnderMaintenance = () => {
	const { lang } = useParams();
  	return (
		<div>
			<Row>
				<Col className={styles.contenedorFila}></Col>
			</Row>
			<Row className="contenedorHeader">
				<Col xs={0} sm={0} md={2} lg={2} xl={2}></Col>
				<Col xs={0} sm={0} md={2} lg={2} xl={2}>
					<img src={require("../../Assets/images/QualitasFunds.png")}/>
					{
						window.location.origin === "http://portalinversoresdev.qualitasfunds.com"
							? <h1 style={{color:'red'}}>Quality Assurance</h1>
							: <h1 style={{color:'red'}}>Develop</h1>
					}
				</Col> 
			</Row>
			<Row style={{marginTop:50}}>
				<Col span={12}>
					<Row>
						<Col span={22} offset="2" className={styles.oops}>
							{
								lang === "es"
									? "Portal del inversor en mantenimiento"
									: "We are in maintenance"
							}
						</Col>
					</Row>
					<Row>
						<Col span={22} offset="2" className={styles.textoGris}>
							{
								lang === "es"
									? "Actualmente el portal del inversor está en mantenimiento, intentelo de nuevo mas tarde"
									: "Currently the investor portal under maintenance, please try again later"
							}
						</Col>
					</Row>
					<Row>
						<Col span={22} offset="2">
							<div className={styles.boton} onClick={()=>window.location.href = "https://qualitasfunds.com/"}>
								{
									lang === "es"
										? "Cerrar"
										: "Close"
								}
							</div>
						</Col>
					</Row>
				</Col>
				<Col span={12}>
					<img src={require("../../Assets/images/underConstruction.jpg")} style={{width:'100%'}}/>
				</Col>
			</Row>
		</div>
  	);
};

export default UnderMaintenance;
