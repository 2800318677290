import { useEffect, useState } from "react";
import { Col, Layout, Row } from "antd";

import FilesViewer, { FilesViewerAPI, useFilesViewer } from '@qualitas/files-viewer';
import FeedbackModal, { useFeedbackModal } from '@qualitas/feedback-modal';

import { IDocuments } from "./IDocuments";

const { Content } = Layout;

const Documents = ({
	language,
	translations,
	idPeipSelected,
	idVehicleSelected,
	getAuthToken,
	appName,
	userEmail
}: IDocuments) => {
	const [shouldShowFileViewer, setShouldShowFileViewer] = useState<boolean>(false);

	useEffect(() => {
		setShouldShowFileViewer(false);
		if (idPeipSelected && idVehicleSelected && language) {
			setShouldShowFileViewer(true);
		}
	}, [idPeipSelected, idVehicleSelected, language])

	const baseUrl = process.env.REACT_APP_WebAPI_BaseURL || '';
    const feedbackModalProps = useFeedbackModal({});
    const api = FilesViewerAPI({
		baseUrl,
		getAuthToken,
		showServiceFeedbackModal: feedbackModalProps.showServiceFeedbackModal
	});

	const fileViewerProps = useFilesViewer({
		api,
		appName,
		idPeip: idPeipSelected,
		idVehicle: idVehicleSelected,
		idLanguage: language === 'en' ? 1 : 2,
		addWatermark: true,
		userEmail
	});
	
	return (
		<Layout style={{ padding: '24px' }}>
			<Content style={{ margin: '0' }}>
				<Row gutter={16}>
					<Col xs={0} sm={0} md={7} lg={5} xl={5}>
						<div className="title-tab">{translations.documentosInversores}</div>
					</Col>
				</Row>
				<Row className="content-wrapper">
					<Col span={24}>
						{shouldShowFileViewer || appName === 'Investor Portal'
							? <FilesViewer
								isLoading={fileViewerProps.isLoading}
								isLoadingDocument={fileViewerProps.isLoadingDocument}
								texts={translations}
								documentToken={fileViewerProps.documentToken}
								isPDFViewerOpen={fileViewerProps.isPDFViewerOpen}
								folderTree={fileViewerProps.folderTree}
								openPDFViewerModal={fileViewerProps.openPDFViewerModal}
								disableRightClick={fileViewerProps.disableRightClick}
								onSelectFile={fileViewerProps.onSelectFile}
								closePDFViewerModal={fileViewerProps.closePDFViewerModal}
								showMenu
								shouldExpandAll={fileViewerProps.shouldExpandAll}
								onExpandFolder={fileViewerProps.onExpandFolder}
								expandedKeys={fileViewerProps.expandedKeys}
								hideLoadingDocument={fileViewerProps.hideLoadingDocument}
							>
								<FeedbackModal {...feedbackModalProps}/>
							</FilesViewer>
							: null
						}
					</Col>
				</Row>
			</Content>
		</Layout>
	);
}

export default Documents;