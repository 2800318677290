import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import Login, { AuthAPI, useAuth } from '@qualitas/auth';

import UnderMaintenance from './components/UnderMaintenance/UnderMaintenance';
import InvestorPortalApp from './apps/InvestorPortal/InvestorPortal';

import { initAPIs } from './Api/api';

const baseUrl = process.env['REACT_APP_WebAPI_BaseURL'];
const App = () => {
	const navigate = useNavigate();

	const authAPI = AuthAPI(baseUrl);
	const authProps = useAuth({
		api: authAPI,
		navigate,
		redirectUrl: '/',
		systemMasterName: "Investor Portal",
		redirectPassResetUrl: process.env['REACT_APP_Pass_Reset']
	});

	useEffect(() => {
		initAPIs(authProps.getAuthToken);
	}, []);

	return (
		<Routes>
			<Route path="/login" element={<Login {...authProps} appName={'Investor Portal'}/>}/>
			<Route path='/UnderMaintenance/:lang?' element={<UnderMaintenance/>}/>
			<Route path="*" element={<InvestorPortalApp {...authProps} baseUrl={baseUrl}/>}/>
		</Routes>
	);
};

export default App;