import { useState, useEffect } from 'react';
import { Col, Row } from 'antd';

import VehicleAPI from '../Api/api.Vehicle';
import InvestorsGridAPI from '../Api/api.Investors';

import { menuServices } from '../Servicios/menu.service';
import { reportesService } from '../Servicios/reportes.service';

import { Vehiculo } from '../Modelos/Vehicle.model';
import { IDataGridInvestors } from '../Modelos/dataGridInvestors.model';

import { languages } from '../Assets/idiomas';

const useApp = ({ userName, userContactId, onSelectFile, openFileViewer }: any) => {
	const [defaultTabKey, setDefaultTabKey] = useState('2');
	const [fundSelected, setSelectedFund]: any = useState();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [peipsList, setPeipsList]: any = useState([]);
	const [selectedTab, setSelectedTab] = useState("Inversores");
	const [visible, setVisible] = useState(false);
	const [investorsInfo, setInvestorsInfo] = useState([]);
	const [adText, setAdText] = useState("");
	const [dateList, setDateList] = useState([]);
	//Datos grafico
	const [commitment, setCommitment] = useState<number>();
	const [contributions, setContributions] = useState<number>();
	const [distributions, setDistributions] = useState<number>();

	const [language, setLanguage] = useState(navigator.language.split("-")[0]);
	const [translations, setTranslations]: any = useState([]);

	const [modalVideoVisible, setModalVideoVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [peipVehicle, setPeipVehicle] = useState<string>("Qualitas Funds I FCR");
	const [idPeipSelected, setIdPeipSelected] = useState<number>();
	const [idVehicleSelected, setIdVehicleSelected] = useState<number>();
	const [vehiclesResult, setVehiclesResult] = useState<string[]>([]);
	const [peips, setPeips] = useState<string[]>([]);

	//INVESTORS TABLE
	const [dataInvestors, setDataInvestors] = useState<IDataGridInvestors[]>([]);
	const [dataSourceTree, setDataSourceTree] = useState<any[]>([]);
	const [totalGrid, setTotalGrid] = useState<any[]>([]);

	const [expandedKeys, setExpandedKeys] = useState<string[]>(['Grupo-0']);
	const [checkedKeys, setCheckedKeys] = useState<string[]>();
	const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
	const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

	useEffect(() => {
		if (investorsInfo.length > 0) {
			setDataInvestors(investorsInfo);
			buildGrid(peipVehicle, investorsInfo);
			setCheckedKeys([]);
			setExpandedKeys(['Grupo-0']);
		} else if (userContactId){
			getDataGrid();
		}
	}, [peipVehicle, userContactId]);

	useEffect(() => {
		switch (language) {
			case 'es': setTranslations(languages.es);
				break;
			case 'en': setTranslations(languages.en);
				break;
		}
	}, [language]);

	useEffect(() => {
		if(userContactId && vehiclesResult.length > 0)
			getVehicles();
	}, [vehiclesResult, userContactId]);

	const getUniqueVehicles = (data: any[]) => {
		let uniqVehicle = data.filter((v: any, i: any, a: any) => a.findIndex((t: any) => (t.peipVehicle === v.peipVehicle)) === i);
		return uniqVehicle.map((x:any)=>{return x.peipVehicle});
	};

	const filterGrid = (e: string) => {
		let arrayFiltered = [];
		if (e === "") {
			arrayFiltered = dataInvestors;
		} else {
			arrayFiltered = dataInvestors.filter((x: any) => x.institution
				.toLowerCase().includes(e.toLowerCase()))
		}
		buildGrid(peipVehicle, arrayFiltered);
	};

	const buildGrid = (peipVehicle: string, dataInvestors: any) => {
		let arrayTemp: any = [];
		setDataSourceTree(arrayTemp);
		let arrayChildTemp: any = [];
		let totalArray: any = [{
			commitment: 0,
			amount: 0,
			capital_Gain: 0,
			shares: 0
		}];
		let el: any = {};
		el.expandAction = "click"; 
		el.title = <Row className="fila-tabla">
			<Col xs={24} sm={24} md={8} lg={8} xl={8} className="celda-nombre"><b>{translations.seleccionarTodos}</b></Col>
			<Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda"><b>{translations.compromiso}</b></Col>
			<Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda"><b>{translations.contribuciones}</b></Col>
			<Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda"><b>{translations.distribuciones}</b></Col>
			<Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda"><b>{translations.participaciones}</b></Col>
		</Row>;
		el.key = `Grupo-0`;
		el.colocador = "Col";
		arrayChildTemp = [];
		dataInvestors.map((inv: any, childIndex: number) => {
			if (inv.peipVehicle === peipVehicle) {
				let children: any = {};
				children.title = <Row className="fila-tabla">
					<Col xs={24} sm={24} md={8} lg={8} xl={8} className="celda-child-nombre">{inv.institution} ({inv.sharesType})</Col>
					<Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda-child">{`€ ${inv.commitment}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</Col>
					<Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda-child">{`€ ${inv.amount.toFixed(2)}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</Col>
					<Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda-child">{`€ ${inv.capital_Gain.toFixed(2)}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</Col>
					<Col xs={0} sm={0} md={4} lg={4} xl={4} className="celda-child">{`${inv.shares.toFixed(2)}`.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</Col>
				</Row>;
				children.key = inv.idInvestor;
				children.tipo = "hijo";
				children.colocador = inv.institution;
				arrayChildTemp.push(children);
				totalArray[0].commitment += inv.commitment;
				totalArray[0].amount += inv.amount;
				totalArray[0].capital_Gain += inv.capital_Gain;
				totalArray[0].shares += inv.shares;
			}
			if (childIndex + 1 === dataInvestors.length) {
				el.children = arrayChildTemp;
				arrayTemp.push(el);
				setDataSourceTree(arrayTemp);
				setLoading(false);
				setTotalGrid(totalArray);
				setCommitment(totalArray[0].commitment);
				setContributions(totalArray[0].amount);
				setDistributions(totalArray[0].capital_Gain);
			}
		});
	};

	const onExpand = (expandedKeys: any) => {
		setExpandedKeys(expandedKeys);
		setAutoExpandParent(false);
	};

	const onCheck = (checkedKeys: any, info: any) => {
		setCheckedKeys(checkedKeys);
		let sumaCompromiso = 0;
		let sumaContribuciones = 0;
		let sumaDistribucuiones = 0;
		checkedKeys.map((key: any, indexKey: number) => {
			investorsInfo.map((child: any, indexRow: number) => {
				if (parseInt(key) === parseInt(child.idInvestor)) {
					sumaCompromiso = sumaCompromiso + child.Commitment;
					sumaContribuciones = sumaContribuciones + child.Amount;
					sumaDistribucuiones = sumaDistribucuiones + child.Capital_Gain;
				}
				if (indexKey + 1 === checkedKeys.length && indexRow + 1 === investorsInfo.length) {
					setCommitment(sumaCompromiso);
					setContributions(sumaContribuciones);
					setDistributions(sumaDistribucuiones);
				}
			});
		});
		let arrayCheckedKeys: any = checkedKeys;
		if (checkedKeys.length === 0) {
			setSelectedRowKeys([]);
			investorsInfo.filter((x: any) => x.peipVehicle === peipVehicle)
				.map((child: any, indexRow: number) => {
					sumaCompromiso = sumaCompromiso + child.Commitment;
					sumaContribuciones = sumaContribuciones + child.Amount;
					sumaDistribucuiones = sumaDistribucuiones + child.Capital_Gain;
					if (indexRow + 1 === investorsInfo.filter((x: any) => x.peipVehicle === peipVehicle).length) {
						setCommitment(sumaCompromiso);
						setContributions(sumaContribuciones);
						setDistributions(sumaDistribucuiones);
					}
				});
		}
		setSelectedRowKeys(arrayCheckedKeys);
		info.checkedNodes.map((nodo: any, i: any) => {
			setSelectedRowKeys(arrayCheckedKeys);
			if (nodo.tipo === undefined) {
				arrayCheckedKeys = arrayCheckedKeys.filter((x: any) => x !== nodo.key);
			}
			if (i + 1 === info.checkedNodes.length) {
				setSelectedRowKeys(arrayCheckedKeys);
			}
		});
	};

	const onSelect = (selectedKeys: any, info: any) => setSelectedKeys(selectedKeys);

	const handleFundClick = (e: any) => {
		if( peipsList.length > 0) {
			const fundSelected = peipsList.filter((fund: any) => fund.peipNameLong === e.key)[0];
			setIdPeipSelected(fundSelected.idPeip);
			setIdVehicleSelected(fundSelected.idVehicle)
		}
		setSelectedRowKeys([]);
		setSelectedFund(e.key);
		setPeipVehicle(e.key);
	};

	const getPeips = (vehicles: Vehiculo[]) => {
		new menuServices().getPeips(userContactId).then((data: any) => {
			let arrayFondons: any = [];
			let arrayVehicles: any[] = [];
			data.map((x: any) => (
				arrayFondons.push(x.peipNameShort)
			));
			setPeips(arrayFondons);
			vehicles.map((x: any) => {
				let peip = data.filter((y: any) => y.idPeip === x.idPeip);
				if (peip.length > 0) {
					let el = {
						idVehicle: x.idVehicle,
						idPeip: x.idPeip,
						peipNameLong: `${peip[0].peipNameLong} ${x.category}`,
						fundName: peip[0].peipNameLong
					}
					vehiclesResult.map((x: string) => {
						if (x === el.peipNameLong) {
							arrayVehicles.push(el);
						}

						return x;
					})
				}
				return x;
			});
			let arrayVehiclesSorted = arrayVehicles.sort((a: any, b: any) => a.peipNameLong.localeCompare(b.peipNameLong));
			if (!fundSelected) {
				if (arrayVehiclesSorted.length > 0) {
					setSelectedFund(arrayVehiclesSorted[0].peipNameLong);
					handleFundClick({ key: arrayVehiclesSorted[0].peipNameLong });
					setIdPeipSelected(arrayVehiclesSorted[0].idPeip);
					setIdVehicleSelected(arrayVehiclesSorted[0].idVehicle)
				}
			}
			setPeipsList(arrayVehiclesSorted.sort((a: any, b: any) => a.peipNameLong.localeCompare(b.peipNameLong)));
		});
	};

	const getVehicles = () => {
		VehicleAPI.get().then(res => {
			getPeips(res);
		})
	};

	const bottomExcelVersion = () => {
		setVisible(false);
		new reportesService().descargarExcel('Resumen_De_Posicion_Fondo.xlsx', 'https://qep1.sharepoint.com/sites/Portalweb/Reportes/Excel_2007/Resumen_De_Posicion_Fondo.xlsx', userContactId);
	};

	const topExcelVersion = () => {
		setVisible(false);
		new reportesService().descargarExcel('Resumen_De_Posicion_Fondo.xlsx', 'https://qep1.sharepoint.com/sites/Portalweb/Reportes/Excel/Resumen_De_Posicion_Fondo.xlsx', userContactId);
	};
	
	const downloadDocuments = (name: string, url: string) => {
		onSelectFile(url);
		openFileViewer();
	};

	const onTabChange = (key: any) => {
		setDefaultTabKey(key);
	};

	const getDataGrid = () => {
		setLoading(true);
		setInvestorsInfo([]);
		InvestorsGridAPI.get(userContactId).then((data: any) => {
			setVehiclesResult(getUniqueVehicles(data));
			setDateList(data);			let arraySumaPorInversor: any = [];
			let arrayGrupos: any = [];
			data.map((x: IDataGridInvestors, i: number) => {
				let investorIndex = arraySumaPorInversor
					.map((e: any) => `${e.fondo}-${e.institution}-${e.sharesType}-${e.peipVehicle}`)
					.indexOf(`${x.idPeip}-${x.institutionLong}-${x.sharesType}-${x.peipVehicle}`);
				if (investorIndex === -1) {
					arraySumaPorInversor.push({
						institution: x.institutionLong,
						fondo: x.idPeip,
						idInvestor: x.idInvestor,
						commitment: x.commitment,
						amount: x.amount,
						capital_Gain: x.capGain_Buyback2,
						shares: x.shares,
						colocador: x.colocador,
						key: i,
						sharesType: x.sharesType,
						peipVehicle:x.peipVehicle
					});
				} else {
					arraySumaPorInversor[investorIndex].commitment = arraySumaPorInversor[investorIndex].commitment + x.commitment;
					arraySumaPorInversor[investorIndex].amount = arraySumaPorInversor[investorIndex].amount + x.amount;
					arraySumaPorInversor[investorIndex].capital_Gain = arraySumaPorInversor[investorIndex].capital_Gain + x.capGain_Buyback2;
					arraySumaPorInversor[investorIndex].shares = arraySumaPorInversor[investorIndex].shares + x.shares;
				}

				//cargar array de grupos
				let groupIndex = arrayGrupos.map((e: any) => { return `${e.Colocador}-${e.Fondo}` })
					.indexOf(`${x.colocador}-${x.idPeip}`);
				if (groupIndex === -1) {
					arrayGrupos.push({
						Colocador: x.colocador,
						Fondo: x.idPeip,
						Commitment: x.commitment,
						Amount: x.amount,
						Capital_Gain: x.capital_Gain,
						Shares: x.shares,
					});
				} else {
					arrayGrupos[groupIndex].Commitment += x.commitment
					arrayGrupos[groupIndex].Amount += x.amount
					arrayGrupos[groupIndex].Capital_Gain += x.capGain_Buyback2
					arrayGrupos[groupIndex].Shares += x.shares
				}
				if (i + 1 === data.length) {
					setDataInvestors(arraySumaPorInversor);
					setInvestorsInfo(arraySumaPorInversor);
					buildGrid(peipVehicle, arraySumaPorInversor);
				}
			})
		}).catch(err => console.error(err));
	};

	return {
		language,
		setLanguage,
		translations,
		selectedTab,
		setSelectedTab,
		peipsList,
		defaultTabKey,
		setDefaultTabKey,
		commitment,
		distributions,
		contributions,
		modalVideoVisible,
		setModalVideoVisible,
		loading,
		selectedRowKeys,
		fundSelected,
		adText,
		setAdText,
		dateList,
		idPeipSelected,
		idVehicleSelected,
		visible,
		setVisible,
		peips,

		handleFundClick,
		onTabChange,
		downloadDocuments,
		bottomExcelVersion,
		topExcelVersion,


		dataSourceTree,
		totalGrid,
		expandedKeys,
		checkedKeys,
		selectedKeys,
		autoExpandParent,
		filterGrid,
		onSelect,
		onExpand,
		onCheck
	};
};

export default useApp;