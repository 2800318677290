import { FileProtectOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Menu, Row, Select, Tabs } from "antd";
import Grafico from "../InformacionInteres/Grafico/Grafico";

const { Sider } = Layout;
const { TabPane } = Tabs;

const AsideMenuLayout = ({
	translations,
	language,
	loading,
	peipsList,
	handleFundClick,
	defaultTabKey,
	onTabChange,
	commitment,
	contributions,
	distributions,
	setModalVideoVisible,
	downloadDocuments,
	children
}: any) => (
	<Layout className="layout">
		<Sider breakpoint="lg"
			collapsedWidth="0"
			style={{ backgroundColor: '#fff', color: '#001435' }}
			className="layout-sider border-right"
			width={300}
		>
			<div className="border-bottom">
				<div className="section-title" style={{ color: '#001435' }}>{translations.fondos}</div>
				{peipsList.length > 0 ?
					<Menu mode="inline" defaultSelectedKeys={[peipsList[0].peipNameLong]} onClick={handleFundClick}
						style={{
							textAlign: 'left',
							backgroundColor: '#fff',
						}}
					>
						{peipsList.map((peip: any) =>
							<Menu.Item key={`${peip.peipNameLong}`} style={{
								backgroundColor: '#fff',
								color: '#001435'
							}}>
								<FileProtectOutlined translate={undefined} />
								<span className="nav-text">{peip.peipNameLong}</span>
							</Menu.Item>
						)}
					</Menu>
					: null
				}
			</div>

			<div className="aside-menu-container">
				<Tabs 
					defaultActiveKey={defaultTabKey}
					activeKey={defaultTabKey}
					onChange={onTabChange}
					style={{
						textAlign: 'left',
						color: '#001435',
						backgroundColor: '#fff'
					}}
				>
					<TabPane tab={translations.tuInversion} key="1">
						<Grafico
							compromiso={commitment}
							contribuciones={contributions}
							distribuciones={distributions}
							translations={translations}
						/>
					</TabPane>
					<TabPane tab={translations.diaInversor} key="2">
						<img
							alt="Investor Day 2023"
							src={require('../../Assets/images/InvestorDay2023.png')}
							style={{ width: '100%', cursor: 'pointer' }}
							onClick={() => setModalVideoVisible(true)}
						/>
						<div style={{ textAlign: 'center', marginTop: 10 }}>
							<Row>
								<Col span={24}>
									<Select placeholder="Seleccione un documento" style={{ width: '80%' }} onChange={(x) => downloadDocuments(x.split(';')[0], x.split(';')[1])}>
										<Select.Option
											value={
												language === 'es'
													? 'Qualitas Funds Día del Inversor 2023;/Investor Day/Día del Inversor Qualitas Funds 2023.pdf'
													: 'Qualitas Funds Investor Day 2023;/Investor Day/Qualitas Funds Investor Day 2023.pdf'
											}
										>
											{language === 'es' ? 'Qualitas Funds Día del Inversor 2023' : 'Qualitas Funds Investor Day 2023'}
										</Select.Option>
										{language === 'es'
											? <Select.Option
												value={'Informe Anual de Cartera 2022;/Anual Budget Report/Informe Anual de Cartera 2022.pdf'}>
												Informe Anual de Cartera 2022
											</Select.Option>
											: null
										}
										{language === 'es'
											? <Select.Option 
												value={'Q & A Día del Inversor 2023;/Investor Day/QA Día del Inversor 2023.pdf'}
											>
												Q & A Día del Inversor 2023
											</Select.Option>
											: null
										}
									</Select>
								</Col>
							</Row>
							{/* <Row>
								<Col span={12}>
									<Button type="primary" style={{ backgroundColor: userData && userData.ColorPlacementAgent }} onClick={() => downloadDocuments("Qualitas Funds Día del Inversor 2023", language === 'es' ? 'https://qualitasdataroom.blob.core.windows.net/portal-inversor/Día del Inversor Qualitas Funds 2023.pdf' : 'https://qualitasdataroom.blob.core.windows.net/portal-inversor/Qualitas Funds Investor Day 2023.pdf')} loading={loading}>{translations.presentacion}</Button>
								</Col>
								<Col span={12}>
									<Button type="primary" style={{ backgroundColor: userData && userData.ColorPlacementAgent }} onClick={() => downloadDocuments("Q & A Día del Inversor 2022", language === 'es' ? 'https://qualitasdataroom.blob.core.windows.net/portal-inversor/QyA_2022_ES.pdf' : 'https://qualitasdataroom.blob.core.windows.net/portal-inversor/QyA_2022_En.pdf')} loading={loading}>Q & A</Button>
								</Col>
							</Row> */}
						</div>
					</TabPane>
				</Tabs>
			</div>
		</Sider>
		{children}
	</Layout>
);

export default AsideMenuLayout;