const ReportTypes = [
	{
		"IdReport": 4,
		"Name": "CertificadoParticipacion.rpt",
		"Description": "Certificado de participación / Capital Account",
		"Url": "https://qep1.sharepoint.com/sites/Portalweb/Reportes/Reportes/CertificadoParticipacion.rpt"
	},
	{
		"IdReport": 5,
		"Name": "SolicitudDesembolso.rpt",
		"Description": "Solicitud de desembolso / Capital Call Notice ",
		"Url": "https://qep1.sharepoint.com/sites/Portalweb/Reportes/Reportes/SolicitudDesembolso.rpt"
	},
	{
		"IdReport": 9,
		"Name": "CartaCompromiso",
		"Description": "Carta de Compromiso / Commitment Agreement",
		"Url": "/sites/QualitasPortalInversor/Contratos/ContratosInversion/"
	},
	{
		"IdReport": 11,
		"Name": "NotificacionDistribucion.rpt",
		"Description": "Notificación de Distribución / Distribution Notice ",
		"Url": "https://qep1.sharepoint.com/sites/Portalweb/Reportes/Reportes/NotificacionDistribucion.rpt"
	}
];

export default ReportTypes;