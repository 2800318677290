import { AxiosWebAPI } from '../Api/api';
import { IPeips } from '../Modelos/inversores.models';

export class menuServices {
    public getPeips(IdContact: string): Promise<IPeips[]>{
        return new Promise((resolve,reject)=>{
            try {
                AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/masters-api/Peips/GetPeipsByContact`, {
                    params: { IdContact }
                })
                    .then((data) => {
                        if (data !== null) {
                            return resolve(data.data);
                        }
                        else {
                            return reject(null);
                        }
                    });
            } catch (error) {
                return reject(null);
            }
        });
    }

}