import AnalysisAndReporting, { useAnalysisAndReporting, AnalysisAndReportingAPI } from '@qualitas/reporting';

import { IPlanYourInvestment } from './IPlanYourInvestment';

const PlanYourInvestment = ({
	userIdInstitution,
	language,
	baseUrl,
	getAuthToken,
	userPeips,
	showServiceFeedbackModal,
}: IPlanYourInvestment) => {
	const api = AnalysisAndReportingAPI({
		baseUrl,
		getAuthToken: getAuthToken,
		showServiceFeedbackModal
	});
	const reportingProps = useAnalysisAndReporting({
		api,
		showAll: false,
		idInstitution: userIdInstitution,
		powerBIName: `Plan Your Investment (${language})`
	});

	return (
		<div className="reporting">
			{reportingProps.powerBIToken
				? <AnalysisAndReporting
					{...reportingProps}
					filters={[{
						$schema: "http://powerbi.com/product/schema#basic",
						target: {
							table: "QUALITAS",
							column: "FondoQualitas"
						},
						operator: "In",
						values: userPeips
					}]}
				/>
				: null
			}
		</div>
	);
}

export default PlanYourInvestment;