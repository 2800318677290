import axios from 'axios';

export class Utils {

  	static URL_CONFIG_FILE: string = `${window.location.origin}/assets/config.js`

	public static getConfig(): Promise<any> {
		return new Promise((resolve, reject) => {
			try {
				axios.get(this.URL_CONFIG_FILE)
					.then((data: any) => {
						if (data !== null) {
							return resolve(data);
						} else {
							return reject(null);
						}
					})
			} catch (error) {
				return reject(null);
			}
		})
	};
};