import { useState, useEffect } from 'react';
import { Select, DatePicker, Button, message } from 'antd';
import { reportesService } from '../../../Servicios/reportes.service';
import dayjs from 'dayjs';
import DocumentsService from '../../../Servicios/documentos.service';
import PDFAPI from '../../../Api/api.PDF';
import ReportTypes from '../../../config/ReportTypes';

import './DocumentosInversor.css';

const _reportesServices = new reportesService();
const { Option } = Select;

const DocumentosInversor = (props: any) => {
	const [reportTypes, setReportTypes] = useState<any[]>([]);
	const [reportTypeSelected, setReportTypeSelected]: any = useState([]);
	const [distributionDates, setDistributionDates] = useState([]);
	const [capitalCallDates, setCapitalCallDates] = useState([]);
	const [selectedDate, setSelectedDate] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getReportTypes();
		if (props.selectedRowKeys.length === 0) {
			setReportTypeSelected([]);
		}
		getInvestorsData();
	}, [props.fondoSeleccionado, props.selectedRowKeys, props.language]);

	const getInvestorsData = () => {
		setCapitalCallDates([]);

		let capitalCallDates: any = [];
		let distributionDates: any = [];
		props.listadoFechas.forEach((item: any, indexItem: number) => {
			if (item.type === 'CapitalCalls') {
				//Si es un solo inversor
				if (props.selectedRowKeys.length < 2) {
					let existe = capitalCallDates.map(function (e: any) { return e; })
						.indexOf(dayjs(item.date.split('T')[0]).format('DD/MM/YYYY'));
					if (existe === -1) {
						props.selectedRowKeys.map((llave: any) => {
							if (parseInt(llave) === item.idInvestor && item.amount > 0) {
								capitalCallDates.push(dayjs(item.date.split('T')[0]).format('DD/MM/YYYY'));
							}
							return llave;
						});
					}
				} else {
					let existe = capitalCallDates.map(function (e: any) { return e; })
						.indexOf(dayjs(item.date.split('T')[0]).format('DD/MM/YYYY'));
					if (existe === -1) {
						let arrayInvestorDates = props.selectedRowKeys.map((llave: any) => (
							props.listadoFechas.filter((f: any) => (
								f.IdInvestor === parseInt(llave)
								&& f.Type === "CapitalCalls"
								&& (dayjs(f.Date.split('T')[0]).format('DD/MM/YYYY') === (dayjs(item.Date.split('T')[0]).format('DD/MM/YYYY')))
							))
						));
						let exist = true;
						arrayInvestorDates.map((x: any, index: number) => {
							if (x.length === 0) {
								exist = false;
							}
							if (arrayInvestorDates.length === index + 1) {
								if (exist) {
								capitalCallDates.push(dayjs(item.date.split('T')[0]).format('DD/MM/YYYY'));
								}
							}
							return x;
						});
					}
				}
			}
			if (item.type === 'Distribution') {
				//Si es un solo inversor
				if (props.selectedRowKeys.length < 2) {
					let existe = distributionDates.map(function (e: any) { return e; })
						.indexOf(dayjs(item.date.split('T')[0]).format('DD/MM/YYYY'));
					if (existe === -1) {
						props.selectedRowKeys.map((llave: any) => {
							if (parseInt(llave) === item.idInvestor) {
								distributionDates.push(dayjs(item.date.split('T')[0]).format('DD/MM/YYYY'));
							}
							return llave;
						});
					}
				} else {
					let existe = distributionDates.map(function (e: any) { return e; })
						.indexOf(dayjs(item.date.split('T')[0]).format('DD/MM/YYYY'));
					if (existe === -1) {
						let arrayInvestorDates = props.selectedRowKeys.map((llave: any) => (
							props.listadoFechas.filter((f: any) => (
								f.IdInvestor === parseInt(llave)
								&& f.Type === "Distribution"
								&& (dayjs(f.Date.split('T')[0]).format('DD/MM/YYYY') === (dayjs(item.date.split('T')[0]).format('DD/MM/YYYY')))
							))
						));
						let exist = true;
						arrayInvestorDates.map((x: any, index: number) => {
							if (x.length === 0) {
								exist = false;
							}
							if (arrayInvestorDates.length === index + 1) {
								if (exist) {
								distributionDates.push(dayjs(item.date.split('T')[0]).format('DD/MM/YYYY'));
								}
							}
							return x;
						});
					}
				}
			}
			if (indexItem + 1 && props.listadoFechas.length === indexItem + 1) {
				capitalCallDates.sort(function (a: any, b: any) {
					a = a.split('/').reverse().join('');
					b = b.split('/').reverse().join('');
					return a > b ? 1 : a < b ? -1 : 0;
				});
				distributionDates.sort(function (a: any, b: any) {
					a = a.split('/').reverse().join('');
					b = b.split('/').reverse().join('');
					return a > b ? 1 : a < b ? -1 : 0;
				});
				setCapitalCallDates(capitalCallDates);
				setDistributionDates(distributionDates);
			}
		});
	};

	const getReportTypes = () => {
		const newReportTypes: any[] = [];
		ReportTypes.map((reportType: any, i: number) => {
			if (reportType.Name === 'Anuncio') {
				if (props.language === "en") {
					props.setTextoAnuncio(reportType.Description);
				} else {
					props.setTextoAnuncio(reportType.Url);
				}
			}
			if(reportType.Description.split("/").length > 1) {
				reportType.Description = props.idioma === "en" ? reportType.Description.split("/")[1] : reportType.Description.split("/")[0];
			}
			newReportTypes.push(reportType);
			return reportType;
		});
		setReportTypes(newReportTypes);
	};

	const consultarReporte = () => {
		if (selectedDate === "") {
			message.warning("Debe seleccionar una fecha");
		} else {
			setLoading(true);
			if (reportTypeSelected.Name === "CertificadoParticipacion.rpt" || reportTypeSelected.Name === "SolicitudDesembolso.rpt" || reportTypeSelected.Name === "NotificacionDistribucion.rpt") {
				switch(reportTypeSelected.Name) {
					case "CertificadoParticipacion.rpt":
						generarCapitalAccount();
						break;
					case "SolicitudDesembolso.rpt":
						generarCapitalCall();
						break;
					case "NotificacionDistribucion.rpt":
						GenerateDistributionNotice();
						break;
				}
			} else {
				let fecha = selectedDate.split("/"); 
				_reportesServices.obtenerReportes(reportTypeSelected.Name, reportTypeSelected.Url, `InvId=${props.selectedRowKeys.toString()};Date=${selectedDate}`,fecha[2],fecha[1],fecha[0])
					.then((resolve: any) => {
						if (resolve.length > 0) {
							window.open(resolve[0], '_blank');
							window.open(resolve[1], '_blank');
						}
						else {
							window.open(resolve[0], '_blank');
						}
						setLoading(false);
					})
					.catch(err => {
						message.warning('El documento no está disponible, pongase en contacto con el administrador');
						setLoading(false);
					})
			}
		}
	};

	const GenerateDistributionNotice = () => {
		let fecha = selectedDate.split("/");
		let data = {
			investorIdList: props.selectedRowKeys,
			date: `${fecha[2]}-${fecha[1]}-${fecha[0]}`,
			language: props.language.toUpperCase(),
		};
		PDFAPI.GenerateDistributionNotice(data).then(res=>{
			var blob = new Blob([res], {type: "application/pdf"});
			var link = window.URL.createObjectURL(blob);
			window.open(link);
			setLoading(false);
		});
	};

	const generarCapitalCall = () => {
		let fecha = selectedDate.split("/");
		let data = {
			investorIdList: props.selectedRowKeys,
			date: `${fecha[2]}-${fecha[1]}-${fecha[0]}`,
			language: props.language.toUpperCase()
		};
		PDFAPI.GenerateCapitalCall(data).then(res=>{
			var blob = new Blob([res], {type: "application/pdf"});
			var link = window.URL.createObjectURL(blob);
			window.open(link);
			setLoading(false);
		});
	};

	const generarCapitalAccount = () => {
		var errorMostrado = false;
		let fecha = selectedDate.split("/");
		let data = {
			investorIdList: props.selectedRowKeys,
			date: `${fecha[2]}-${fecha[1]}-${fecha[0]}`,
			language: props.language.toUpperCase()
		};
		PDFAPI.GenerateCapitalAccount(data).then(res=>{
			var blob = new Blob([res], {type: "application/pdf"});
			var link = window.URL.createObjectURL(blob);
			window.open(link);
			setLoading(false);
		}) 
		.catch((e: any) => {
			if (!errorMostrado) {
				message.warning(`El documento no está disponible para algunos de los inversores seleccioandos,
				pongase en contacto con el administrador`, 8);
				errorMostrado = true;
				setLoading(false);
			}
		});
	}

	const descargaCartaCompromiso = () => {
		var errorMostrado = false;
		setLoading(true);
		props.selectedRowKeys.map((x: any, i: number) => {
			var relativeUrl = `${reportTypeSelected.Url}${x}-Carta de compromiso.pdf`;
			DocumentsService().descargarDocumentos(relativeUrl, `${x}-Carta de compromiso.pdf`)
				.then((resolve: any) => {
					if (resolve.length > 0) {
						window.open(resolve[0], '_blank');
						window.open(resolve[1], '_blank');
					} else {
						window.open(resolve[0], '_blank');
					}
					if (props.selectedRowKeys.length === i + 1) {
						setLoading(false);
					}
				})
				.catch((e: any) => {
					if (!errorMostrado) {
						message.warning(`El documento no está disponible para algunos de los inversores seleccioandos,
						pongase en contacto con el administrador`, 8);
						errorMostrado = true;
					}
					if (props.selectedRowKeys.length === i + 1) {
						setLoading(false);
					}
				})
			return x;
		});
	};

	const disabledDate = (current: any) => current && current > dayjs().endOf('day');

	const handleChangeTipoReporte = (e: any) => {
		setReportTypeSelected(reportTypes.filter((x: any) => x.IdReport === e)[0]);
	};

  	return (
    	props.selectedRowKeys.length > 0 ?
      		<div className="contenedor-documentos-inversor">
				<div className="section-title">{props.language === "en" ? "Investor documents" : "Documentos del inversor:"}</div>
        		<>
					<Select defaultValue={props.language === "en" ? "Document Type" : "Tipo de documento"} style={{ width: '40%' }} onChange={handleChangeTipoReporte}
						disabled={props.selectedRowKeys.length > 0 ? false : true}
					>
						{
							reportTypes.map((x: any) =>
								x.Name !== "Anuncio" ?
									<Option value={x.IdReport}>{x.Description}</Option>
									: null
							)
						}
					</Select>
          			{reportTypeSelected ?
            			<>
							{
								reportTypeSelected.Name === 'CartaCompromiso' ?
									<Button loading={loading} onClick={descargaCartaCompromiso} type="primary" style={{ margin: '10px',backgroundColor:'#001435' }} className='btnGenerarReporte'>{props.language === "en" ? "Open Document" : "Abrir documento"}</Button>
									: null
							}
							{
								reportTypeSelected.Name === 'CertificadoParticipacion.rpt' ?
									<>
										<DatePicker
											style={{ margin: '10px', width: 160 }}
											disabledDate={disabledDate}
											format='DD/MM/YYYY'
											onChange={(val: any) => setSelectedDate(dayjs(val).format("DD/MM/YYYY"))} />
										<Button loading={loading} className='btnGenerarReporte' style={{ margin: '10px',backgroundColor:'#001435' }} type="primary" onClick={consultarReporte}>{props.language === "en" ? "Generate report" : "Generar"}</Button>
									</>
									: null
							}
							{
								reportTypeSelected.Name === 'SolicitudDesembolso.rpt' ?
									capitalCallDates.length > 0 ?
										<>
											<Select defaultValue={props.language === "en" ? "Select date" : "Seleccione una fecha"} style={{ margin: '10px', width: 160 }} onChange={(val: any) => setSelectedDate(val)}>
												{capitalCallDates.map((x: any) => <Option value={x}>{x}</Option>)}
											</Select>
											<Button className='btnGenerarReporte' loading={loading} type="primary" style={{ margin: '10px',backgroundColor:'#001435' }} onClick={consultarReporte}>{props.language === "en" ? "Generate report" : "Generar"}</Button>
										</>
										: null
									: null
							}
							{
								reportTypeSelected.Name === 'NotificacionDistribucion.rpt' ?
									distributionDates.length > 0 ?
										<>
											<Select defaultValue={props.language === "en" ? "Select date" : "Seleccione una fecha"} style={{ margin: '10px', width: 160 }} onChange={(val: any) => setSelectedDate(val)}>
												{distributionDates.map((x: any) => <Option value={x}>{x}</Option>)}
											</Select>
											<Button
												className='btnGenerarReporte'
												type="primary" style={{ margin: '10px',backgroundColor:'#001435' }}
												onClick={consultarReporte}
												loading={loading}
											>
												{props.language === "en" ? "Generate report" : "Generar"}
											</Button>
										</>
										: null
									: null
							}
            			</>
            			: null
          			}
        		</>
      		</div>
      		:
      		null
  	);
};

export default DocumentosInversor;
