import React, {useState} from 'react';
import './marquee.css';

const Marquee = (props:any) => {
    return (
        <div className="marquee">
            <span className="marquee__inner">
                    <div dangerouslySetInnerHTML={{ __html: props.texto }} />
            </span>
        </div>
    );
};

export default Marquee;
