import React, { useEffect } from 'react';
import { INotificationsProps } from './INotificationsProps';
import styles from './Notifications.module.scss';
import NotificationsAPI from '../../Api/api.notifications';
import Swal from 'sweetalert2'

const Notifications: React.FC<INotificationsProps> = (props) => {
	useEffect(() => {
		getNotification();
	}, []);

	const getNotification = () => {
		NotificationsAPI.GetInvestorPortalNotifications(props.userIdInstitution).then(res => {
			if(res) {
				Swal.fire({
					html: `${props.language === "es" ? res.notification_es : res.notification_en}`,
					icon: 'info',
					confirmButtonColor: 'rgb(73,114,177)',
					cancelButtonText: 'Ok',
					confirmButtonText: props.translations.noMostrarMas,
					showCancelButton: true
				}).then((result) => {
					if(result.value) {
						dontShowAnyMore(res.id);
					}
				})
			}
		});
	};

	const dontShowAnyMore = (id:number) => NotificationsAPI.DontShowAnyMore(id);

	return (
		<div className={`${styles.notifications}`} style={{textAlign:'justify'}}>
		</div>
	);
};

export default Notifications;
