import { AxiosServiceAPI } from '../Api/api';

const DocumentsService = () => ({
    obtenerDocumentos: (libraryPath:string):Promise<any[]> => {
        return new Promise((resolve,reject)=>{  
            try {
                AxiosServiceAPI.get(`/investors/sharepoint/files?libraryPath=${libraryPath}`)
                    .then((data) => { 
                        if (data !== null) {
                            return resolve(data.data.result);
                        } else {
                            return reject(data);
                        }
                    })
                    .catch(data=>{
                        let system = window.location.pathname;
                        window.location.href = `/login${system}`;
                        return reject(data);
                });
            } catch (error) {
                return reject(error);
            }
        });
    },

    descargarDocumentos: (relativeUrl: string, name: string): Promise<any> => {
        return new Promise((resolve, reject)=>{
            try {
                AxiosServiceAPI.get(`/investors/sharepoint/download?relativeUrl=${relativeUrl}&name=${name}`)
                    .then((data:any) => { 
                        if (data.data.code === 200) {
                            return resolve(data.data.result);
                        } else {
                            return reject(null)
                        }
                    });
            } catch (error) {
                return reject(error);
            }
        });
    }
});

export default DocumentsService;