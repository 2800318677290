import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

import BlueBar from '../BlueBar/BlueBar';
import ModalVideo from '../ModalVideo/ModalVideo';

import './Header.scss';

const Header = ({
    setLanguage,
    language,
    translations,
    userName,
    logOut,
    modulesPermitted
}: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [modalVideoVisible, setModalVideoVisible] = useState(false);

    return (
        <>
            <BlueBar
                setLanguage={setLanguage}
                language={language}
                translations={translations}
                userName={userName}
                logOut={logOut}
            />
            <Row className="header-container" align="middle" justify="space-between">
                <Col xs={0} sm={0} md={2} lg={2} xl={2} offset={1}>
                    <img className="logo" alt="logo" src='https://qualitasdataroom.blob.core.windows.net/assets/image-gallery/20210928T164816216-LogoQualitasFunds.png'/>
                </Col>
                <Col xs={24} sm={24} md={3} lg={3} xl={3}
                    className='text-menu'
                    style={{ textDecoration: location.pathname === `/Investors` ? 'underline' : 'none'}}
                >
                    <div onClick={() => navigate("/Investors")}>
                        {translations.inversores}
                    </div>
                </Col>
                <Col xs={24} sm={24} md={3} lg={3} xl={3}
                    className='text-menu'
                    style={{ textDecoration: location.pathname === `/Documents` ? 'underline' : 'none'}}
                >
                    <div onClick={() => navigate("/Documents")}>
                        {translations.documentos}
                    </div>
                </Col>
                {modulesPermitted.includes('AnalyzeYourInvestment')
                    ? <Col xs={24} sm={24} md={3} lg={3} xl={3}
                        className='text-menu'
                        style={{ textDecoration: location.pathname === `/AnalyzeYourInvestment` ? 'underline' : 'none'}}
                    >
                        <div onClick={() => navigate("/AnalyzeYourInvestment")}>
                            {translations.analiza}
                        </div>
                    </Col>
                    : null
                }
                {modulesPermitted.includes('PlanYourInvestment')
                    ? <Col xs={24} sm={24} md={3} lg={3} xl={3}
                        className='text-menu'
                        style={{ textDecoration: location.pathname === `/PlanYourInvestment` ? 'underline' : 'none'}}
                    >
                        <div onClick={() => navigate("/PlanYourInvestment")}>
                            {translations.planifica}
                        </div>
                    </Col>
                    : null
                }
                
                <Col className="tutorial" xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Button
                        className="btnVideo"
                        size='large'
                        onClick={() => setModalVideoVisible(true)}
                        style={{ backgroundColor: '#001435' }}
                    >
                        <PlayCircleOutlined translate={undefined}/> Tutorial
                    </Button>
                </Col>
            </Row>
            <ModalVideo
                modalVideoVisible={modalVideoVisible}
                setModalVideoVisible={setModalVideoVisible}
                url={language === 'es' ? 'https://player.vimeo.com/video/470096192' : 'https://player.vimeo.com/video/465813566'} 
            />
        </>
    );
};


export default Header;
