import { useState } from 'react';
import { Row, Col, Select } from 'antd';
import { LoginOutlined, UserOutlined } from '@ant-design/icons';

import { IBlueBar } from './IBlueBar';

import './BlueBar.scss';

const { Option } = Select;

const dataRoomUrl = process.env['REACT_APP_Data_Room'];
const BlueBar = ({
	setLanguage,
    language,
    translations,
    userName,
    logOut
}: IBlueBar) => {
	const [MouseOver2, setMouseOver2] = useState<boolean>(false);
	const [MouseOver4, setMouseOver4] = useState<boolean>(false);

	const handleLanguageChange = (languageSelected: string) => {
		localStorage.language = languageSelected;
		setLanguage(languageSelected);
	};

	return (
		<div className="header-blue-bar">
			<Row align="middle">
				<Col xs={24} sm={24} md={2} lg={2} xl={2} className="col-container" offset={1}>
					<Select
						placeholder="Select your language"
						onSelect={handleLanguageChange}
						value={language}
					>
						<Option value="en" key="en">English</Option>
						<Option value="es" key="es">Español</Option>
					</Select>
				</Col>
				<Col xs={24} sm={24} md={3} lg={3} xl={3} offset={10}
					className="col-container"
					onMouseOver={() => setMouseOver2(true)}
					onMouseOut={() => setMouseOver2(false)}
					style={{textDecoration: MouseOver2 ? 'underline':'none', cursor:'pointer'}}
					onClick={() => window.location.href = dataRoomUrl!}
				>
					{translations.dataRoom}
				</Col>
				<Col xs={24} sm={24} md={2} lg={2} xl={2}
					className="topBarOption col-container"
					onMouseOver={()=>setMouseOver4(true)}
					onMouseOut={()=>setMouseOver4(false)}
					style={{textDecoration: MouseOver4 ? 'underline':'none', cursor:'pointer'}}
					onClick={() => window.location.href = "https://qualitasfunds.com/contacto/"}
				>
					{translations.contacto}
				</Col>
				<Col xs={0} sm={0} md={5} lg={5} xl={5} offset={1} flex="end" className="col-container">
					{userName &&
						<>
							<UserOutlined translate={undefined} />&nbsp; {userName} &nbsp;
						</>
					}
					<LoginOutlined className="logout-icon" onClick={logOut} translate={undefined} />
				</Col>
			</Row>
		</div>
	);
};

export default BlueBar;
