import { Col, Layout, Row } from "antd";

import DocumentosInversor from "../../../../components/DocumentosInversor/Reportes/DocumentosInversor";
import Notifications from "../../../../components/Notifications/Notifications";
import InvestorsTable from "../../../../components/InvestorsTable/InvestorsTable";

const { Content } = Layout;

const Investors = ({
	language,
	translations,
	loading,
	fundSelected,
	selectedRowKeys,
	setDefaultTabKey,
	setAdText,
	dateList,
	setVisible,
	userIdInstitution,


	dataSourceTree,
	totalGrid,
	expandedKeys,
	checkedKeys,
	selectedKeys,
	autoExpandParent,
	filterGrid,
	onSelect,
	onExpand,
	onCheck
}: any) => {
	return (
		<Layout style={{ padding: '24px' }}>
			<Content style={{ margin: '0' }}>
				<Row gutter={16}>
					<Col span="24">
						<DocumentosInversor
							selectedRowKeys={selectedRowKeys}
							setDefaultTabKey={setDefaultTabKey}
							fondoSeleccionado={fundSelected}
							setTextoAnuncio={setAdText}
							language={language}
							listadoFechas={dateList}
						/>
					</Col>
				</Row>
				<Row className="content-wrapper">
					<Col span={24}>
						<>
							<Notifications translations={translations} language={language} userIdInstitution={userIdInstitution}/>
							<InvestorsTable
								loading={loading}
								translations={translations}
								filterGrid={filterGrid}
								setVisible={setVisible}
								onExpand={onExpand}
								expandedKeys={expandedKeys}
								autoExpandParent={autoExpandParent}
								onCheck={onCheck}
								checkedKeys={checkedKeys}
								onSelect={onSelect}
								selectedKeys={selectedKeys}
								dataSourceTree={dataSourceTree}
								totalGrid={totalGrid}
							/>
						</>
					</Col>
				</Row>
			</Content>
		</Layout>
	);
}

export default Investors;