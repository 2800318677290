import { AxiosWebAPI } from './api';

const InvestorsGridAPI = {
    get(IdContact:number) {
        return new Promise((resolve) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/masters-api/Investor/GetInvestmentsByContact`, {
                params: { IdContact }
            })
                .then((data) => { 
                    if (data.status === 200) {
                        return resolve(data.data);
                    } else {
                        window.location.href = `/UnderMaintenance/${localStorage.language}`;
                    }
                }).catch(err => window.location.href = `/UnderMaintenance/${localStorage.language}`)
        });
    },
    GetVehicleCategoryByInvestorId(IdContact: number[]): Promise<string[]> {
        return new Promise((resolve) => {
            AxiosWebAPI.post(`/Investor/GetVehicleCategoryByInvestorId`, IdContact)
                .then((data) => { 
                    if (data.status === 200) {
                        return resolve(data.data);
                    } else {
                        window.location.href = `/UnderMaintenance/${localStorage.language}`;
                    }
                }).catch(err => window.location.href = `/UnderMaintenance/${localStorage.language}`)
        });
    }
}

export default InvestorsGridAPI;
