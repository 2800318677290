import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'typeface-playfair-display';
import { Layout, Button, Modal } from 'antd';

import { PrivateRoute } from '@qualitas/auth';
import FeedbackModal, { useFeedbackModal } from '@qualitas/feedback-modal';
import FilesViewer, { FilesViewerAPI, useFilesViewer } from '@qualitas/files-viewer';
import { API, useModulesPermissions } from '@qualitas/modules-permissions';

import useApp from '../../hooks/useApp';

import Investors from './sections/Investors/Investors';
import Documents from '../sections/Documents/Documents';
import AnalyzeYourInvestment from '../sections/AnalyzeYourInvestment/AnalyzeYourInvestment';
import PlanYourInvestment from '../sections/PlanYourInvestment/PlanYourInvestment';

import Marquee from '../../components/Marquee/Marquee';
import Header from '../../components/Header/Header';
import ModalVideo from '../../components/ModalVideo/ModalVideo';
import AsideMenuLayout from '../../components/AsideMenuLayout/AsideMenuLayout';

import '../../App.scss';

const { Footer } = Layout;

const InvestorPortalApp = ({
	validateAuthToken,
	userIdInstitution,
	userName,
	userEmail,
	userContactId,
	logOut,
	getAuthToken,
	baseUrl,
}: any) => {
	const [modulesPermitted, setModulesPermitted] = useState<string[]>([]);
	const feedbackModalProps = useFeedbackModal({});
	
	const api = FilesViewerAPI({
		baseUrl,
		getAuthToken,
		showServiceFeedbackModal: feedbackModalProps.showServiceFeedbackModal
	});
	const fileViewerProps = useFilesViewer({
		api,
		appName: 'Investor Portal',
		addWatermark: true,
		userEmail,
		download: false
	});

	const modulesPermissionAPI = API({
		baseUrl,
		getAuthToken,
		showServiceFeedbackModal: feedbackModalProps.showServiceFeedbackModal
	});
	const {
		modulesPermissions,
		handleSelectApplication
	} = useModulesPermissions(modulesPermissionAPI, userContactId);

	useEffect(() => {
		handleSelectApplication(4);
	}, []);

	useEffect(() => {
		setModulesPermitted(
			modulesPermissions.map((x: any) => {
				if (x.idRol > 0) return x.moduleName;
			})
		);
	}, [modulesPermissions]);
	
	const {
		language,
		setLanguage,
		translations,
		peipsList,
		defaultTabKey,
		setDefaultTabKey,
		commitment,
		distributions,
		contributions,
		modalVideoVisible,
		setModalVideoVisible,
		loading,
		selectedRowKeys,
		fundSelected,
		adText,
		setAdText,
		dateList,
		peips,
		idPeipSelected,
		idVehicleSelected,
		visible,
		setVisible,
		handleFundClick,
		onTabChange,
		downloadDocuments,
		bottomExcelVersion,
		topExcelVersion,

		dataSourceTree,
		totalGrid,
		expandedKeys,
		checkedKeys,
		selectedKeys,
		autoExpandParent,
		filterGrid,
		onSelect,
		onExpand,
		onCheck
	} = useApp({
		userName,
		userContactId,
		onSelectFile: fileViewerProps.onSelectFile,
		openFileViewer: fileViewerProps.openPDFViewerModal,
	});

  	return (
		<div className="investor-portal-app">
			<>
				<Layout>
					<Header
						setLanguage={setLanguage}
						language={language}
						translations={translations}
						userName={userName}
						logOut={logOut}
						modulesPermitted={modulesPermitted}
					/>
					<Routes>
						<Route element={<PrivateRoute validateAuthToken={validateAuthToken}/>}>
							<Route path="/Investors" element={
								<AsideMenuLayout
									translations={translations}
									language={language}
									loading={loading}
									peipsList={peipsList}
									handleFundClick={handleFundClick}
									defaultTabKey={defaultTabKey}
									onTabChange={onTabChange}
									commitment={commitment}
									contributions={contributions}
									distributions={distributions}
									setModalVideoVisible={setModalVideoVisible}
									downloadDocuments={downloadDocuments}
								>
									<Investors
										language={language}
										translations={translations}
										loading={loading}
										fundSelected={fundSelected}
										selectedRowKeys={selectedRowKeys}
										setDefaultTabKey={setDefaultTabKey}
										setAdText={setAdText}
										dateList={dateList}
										setVisible={setVisible}
										userIdInstitution={userIdInstitution}

										dataSourceTree={dataSourceTree}
										totalGrid={totalGrid}
										expandedKeys={expandedKeys}
										checkedKeys={checkedKeys}
										selectedKeys={selectedKeys}
										autoExpandParent={autoExpandParent}
										filterGrid={filterGrid}
										onSelect={onSelect}
										onExpand={onExpand}
										onCheck={onCheck}
									/>
								</AsideMenuLayout>
							}/>
							<Route path="/Documents" element={
								<AsideMenuLayout
									translations={translations}
									language={language}
									loading={loading}
									peipsList={peipsList}
									handleFundClick={handleFundClick}
									defaultTabKey={defaultTabKey}
									onTabChange={onTabChange}
									commitment={commitment}
									contributions={contributions}
									distributions={distributions}
									setModalVideoVisible={setModalVideoVisible}
									downloadDocuments={downloadDocuments}
								>
									<Documents
										language={language}
										translations={translations}
										idPeipSelected={idPeipSelected}
										idVehicleSelected={idVehicleSelected}
										getAuthToken={getAuthToken}
										appName="Investor Portal"
										userEmail={userEmail}
									/>
								</AsideMenuLayout>
								
							}/>

							{modulesPermitted.includes('AnalyzeYourInvestment')
								? <Route path="/AnalyzeYourInvestment" element={
									<>
									{
										language === 'es' 
											? <AnalyzeYourInvestment
												userIdInstitution={userIdInstitution}
												userPeips={peips}
												language={language}
												baseUrl={baseUrl}
												getAuthToken={getAuthToken}
												showServiceFeedbackModal={feedbackModalProps.showServiceFeedbackModal}
											/>
											: null
									}
									{
										language === 'en' 
											? <AnalyzeYourInvestment
												userIdInstitution={userIdInstitution}
												userPeips={peips}
												language={language}
												baseUrl={baseUrl}
												getAuthToken={getAuthToken}
												showServiceFeedbackModal={feedbackModalProps.showServiceFeedbackModal}
											/>
											: null
									}
								</>
								}/>
								: null
							}
							
							{modulesPermitted.includes('PlanYourInvestment')
								? <Route path="/PlanYourInvestment" element={
									<>
										{
											language === 'es' 
												? <PlanYourInvestment
													userIdInstitution={userIdInstitution}
													userPeips={peips}
													language={language}
													baseUrl={baseUrl}
													getAuthToken={getAuthToken}
													showServiceFeedbackModal={feedbackModalProps.showServiceFeedbackModal}
												/>
												: null
										}
										{
											language === 'en' 
												? <PlanYourInvestment
													userIdInstitution={userIdInstitution}
													userPeips={peips}
													language={language}
													baseUrl={baseUrl}
													getAuthToken={getAuthToken}
													showServiceFeedbackModal={feedbackModalProps.showServiceFeedbackModal}
												/>
												: null
										}
									</>
								}/>
								: null
							}
						</Route>
						<Route path="*" element={<Navigate to='/Investors'/>}/>
					</Routes>
					<Footer style={{ backgroundColor: '#fff', display: 'none' }}>
						<Marquee texto={adText} language={language}></Marquee>
					</Footer>
				</Layout>

				<FeedbackModal {...feedbackModalProps} />

				<Modal
					open={visible}
					title="Cual es su versión de Excel?"
					onCancel={() => setVisible(false)}
					footer={[
						<Button key="back" style={{ backgroundColor: '#9191a3', color: 'white' }} onClick={bottomExcelVersion}>
							2007 o anterior
						</Button>,
						<Button key="submit" type="primary" style={{ backgroundColor: '#133263' }} onClick={topExcelVersion}>
							2010 o posterior
						</Button>
					]}
				>
					<p>Seleccione su versión de Excel</p>
				</Modal>

				<>
					{
						language === 'es' ?
							<ModalVideo
								modalVideoVisible={modalVideoVisible}
								setModalVideoVisible={setModalVideoVisible}
								url='https://player.vimeo.com/video/826021484?h=0c5f73cfbb'
							/>
							:
							<ModalVideo
								modalVideoVisible={modalVideoVisible}
								setModalVideoVisible={setModalVideoVisible}
								url='https://player.vimeo.com/video/825986666?h=3e3fb2bcaf'
							/>
					}
				</>
				<FilesViewer {...fileViewerProps}/>
			</>
		</div>
	);
}

export default InvestorPortalApp;
