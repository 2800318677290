const languages = {
    es:{
      idioma: "Idioma",
      inversores: "Inversores",
      documentos: "Documentos",
      analiza: "Analiza tu inversión",
      cargando: "Cargando Datos...",
      buscar: "Buscar inversor",
      busquedaHolder: "Escriba el nombre del inversor",
      descargarExcel: "Descargar Excel",
      grupo: "Grupo",
      compromiso: "Compromiso",
      compromisoGrafico: "Comp.",
      contribuciones: "Contribuciones",
      distribuciones: "Distribuciones",
      participaciones: "Participaciones",
      tuInversion: "Resumen",
      documentosInversores: "Documentos de inversores",
      nombreDocumento: "Nombre del Documento",
      fondos: "Fondos",
      anuncios: "Anuncios",
      portalInversor: "Portal del inversor",
      contacto: "Contacto",
      accesoDenegado: "Acceso denegado",
      accesoDenegadoTexto: "El usuario actual no tiene acceso a esta aplicación",
      SeleccionaDocumento: "Selecciona un documento",
      ampliar: "Ampliar",
      seleccionarTodos: "Seleccionar Todos",
      diaInversor: "Día del Inversor",
      presentacion: "Presentación",
      noMostrarMas: "No volver a mostrar",
      planifica: "Planifica tu inversión",
      videos: "Vídeos",
      dataRoom: "Data Room",
      enlarge: 'Pantalla completa',
      download: 'Descargar'
    },
    en: {
      idioma: "Language",
      inversores: "Investors",
      documentos: "Documents",
      analiza: "Analyze Your Investment",
      cargando: "Loading Data...",
      buscar: "Search investor",
      busquedaHolder: "investor name",
      descargarExcel: "Download Excel",
      grupo: "Group",
      compromiso: "Commitment",
      compromisoGrafico: "Comm.",
      contribuciones: "Amount",
      distribuciones: "Capital Gain",
      participaciones: "Shares",
      tuInversion: "Resume",
      documentosInversores: "Investor documents",
      nombreDocumento: "Document name",
      fondos: "Funds",
      anuncios: "Announcements",
      portalInversor: "Investor portal",
      contacto: "Contact",
      accesoDenegado: "Access denied",
      accesoDenegadoTexto: "The current user does not have access to this application",
      SeleccionaDocumento: "Select a Document",
      ampliar: "Open",
      seleccionarTodos: "Select All",
      diaInversor: "Investor Day",
      presentacion: "Presentation",
      noMostrarMas: "Don't show again",
      planifica: "Plan your investment",
      videos: "Videos",
      dataRoom: "Data Room",
      enlarge: 'Full screen',
      download: 'Download'
    }
  }
  export {languages};